import React from 'react';
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby';
import { Interpolation, keyframes, Theme } from '@emotion/react';
import Container from '../primitives/grid/container';
import { colors } from '../primitives/colors';
import { useLanguage } from '../language/language-provider';
import Logo from '../primitives/logo/logo.svg';

// eslint-disable-next-line import/no-cycle
import { StyledButtonAsLink } from '../primitives/styled-button';
import Link, { getLink } from '../primitives/link';
import { borderRadius, breakpoints } from '../primitives/tokens';
import VisuallyHidden from '../primitives/visually-hidden';
import { Drawer, DrawerContent } from '../primitives/drawer';
import Typography, { smallTextStyles } from '../primitives/typography';
import ScrollEffect from '../animations/components/scroll-effect';
import Button from '../primitives/button';
import Background from '../primitives/backgrounds';
import ShadowLogo from './shadow-logo.svg';
import hierarchicalMenu, { hierarchicalMenuSelectChild } from '../../util/hierarchical-menu';
import type { ParsedMenuItem } from '../../util/hierarchical-menu';
import LanguageSwitcher from '../language/language-switcher';
import DirectionTransition, { DirectionTransitionDirection } from '../animations/transitions/direction-transition';
import MenuCaretLeft from './menu-caret-left.svg';
import MenuCaretRight from './menu-caret-right.svg';
import useDatoRouter from "../language/use-dato-router";
import {useLocation} from "@reach/router";

const MobileHeader = ({ hasAnnouncement }: { hasAnnouncement: boolean }): JSX.Element => {
    const { language } = useLanguage();

    const [open, setOpen] = React.useState(false);
    const [subMenu, setSubmenu] = React.useState<null|ParsedMenuItem>(null);
    const [menuDirection, setMenuDirection] = React.useState<DirectionTransitionDirection>('forward');

    const drawerRef = React.useRef<HTMLDivElement>(null);
    const getPath = useDatoRouter();

    const data = useStaticQuery<Queries.MobileHeaderDataQuery>(graphql`
        query MobileHeaderData {
            auSupplemental: datoCmsHeaderSupplemental(locale: "en-AU") {
                emailAddress
                headerGetStartedLink {
                    ...LinkWithTitleProps
                }
            },
            ukSupplemental: datoCmsHeaderSupplemental(locale: "en-GB") {
                emailAddress
                headerGetStartedLink {
                    ...LinkWithTitleProps
                }
            }
            usSupplemental: datoCmsHeaderSupplemental(locale: "en-US") {
                emailAddress
                headerGetStartedLink {
                    ...LinkWithTitleProps
                }
            }
            auMenu: allDatoCmsHeaderMenuItem(locale: "en-AU") {
                nodes {
                    id
                    title
                    text
                    mediaItem {
                        ...MediaItemProps
                    }
                    position
                    treeParent {
                        id
                    }
                    hideForLocale
                    link {
                        ... on DatoCmsExternalLink {
                            linkExternal: link
                        }
                        ... on DatoCmsInternalLink {
                            linkInternal: link {
                                ... on DatoCmsPage {
                                    permalink
                                }
                                ... on DatoCmsArticle {
                                    permalink
                                }
                                ... on DatoCmsComparison {
                                    permalink
                                }
                                ... on DatoCmsCaseStudy {
                                    permalink
                                }
                                ... on DatoCmsJob {
                                    permalink
                                }
                                ... on DatoCmsArticleTag {
                                    permalink
                                }
                                ... on DatoCmsPolicy {
                                    permalink
                                }
                            }
                        }
                    }
                }
            }
            ukMenu: allDatoCmsHeaderMenuItem(locale: "en-GB") {
                nodes {
                    id
                    title
                    text
                    mediaItem {
                        ...MediaItemProps
                    }
                    position
                    treeParent {
                        id
                    }
                    hideForLocale
                    link {
                        ... on DatoCmsExternalLink {
                            linkExternal: link
                        }
                        ... on DatoCmsInternalLink {
                            linkInternal: link {
                                ... on DatoCmsPage {
                                    permalink
                                }
                                ... on DatoCmsArticle {
                                    permalink
                                }
                                ... on DatoCmsComparison {
                                    permalink
                                }
                                ... on DatoCmsCaseStudy {
                                    permalink
                                }
                                ... on DatoCmsJob {
                                    permalink
                                }
                                ... on DatoCmsArticleTag {
                                    permalink
                                }
                                ... on DatoCmsPolicy {
                                    permalink
                                }
                            }
                        }
                    }
                }
            }
            usMenu: allDatoCmsHeaderMenuItem(locale: "en-US") {
                nodes {
                    id
                    title
                    text
                    mediaItem {
                        ...MediaItemProps
                    }
                    position
                    treeParent {
                        id
                    }
                    hideForLocale
                    link {
                        ... on DatoCmsExternalLink {
                            linkExternal: link
                        }
                        ... on DatoCmsInternalLink {
                            linkInternal: link {
                                ... on DatoCmsPage {
                                    permalink
                                }
                                ... on DatoCmsArticle {
                                    permalink
                                }
                                ... on DatoCmsComparison {
                                    permalink
                                }
                                ... on DatoCmsCaseStudy {
                                    permalink
                                }
                                ... on DatoCmsJob {
                                    permalink
                                }
                                ... on DatoCmsArticleTag {
                                    permalink
                                }
                                ... on DatoCmsPolicy {
                                    permalink
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const localSupplementalData = data[`${language}Supplemental`];
    const rawMenuData = data[`${language}Menu`].nodes.filter(x => {
        return !x.hideForLocale
    });

    const menu = hierarchicalMenu(rawMenuData);

    const menuItemSet = subMenu || menu;

    const backgroundAnimation = keyframes({
        '0%': {
            opacity: 0.3,
            transform: 'scale(5)',
        },
        '100%': {
            opacity: 1,
            transform: 'scale(1)',
        },
    });

    const backgroundAnimationLogo = keyframes({
        '0%': {
            opacity: 0,
            transform: 'translateY(20px)',
        },
        '100%': {
            opacity: 1,
            transform: 'translateY(0)',
        },
    });

    const location = useLocation()

    return (
        <>
            <div css={{
                position: 'relative',
                width: '100%',
                pointerEvents: 'auto',
                borderBottom: `1px solid var(--header-color-7, ${colors.grey100})`,
            }}
            >
                <div ref={drawerRef} />
                <div>
                    <Container
                        css={{
                            position: 'relative',
                            zIndex: 2000,
                            transition: '0.3s',
                        }}
                        style={{
                            background: open ? 'linear-gradient(180deg, #FD6C1C, transparent)' : 'transparent',
                        }}
                    >
                        <div css={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        >
                            <Link
                                to="/"
                                css={{
                                    padding: '24px 0',
                                    left: '0',
                                    color: colors.primaryOrange,
                                    zIndex: 2,
                                }}
                                style={{
                                    color: open ? colors.shadesWhite : 'var(--header-color-2, var(--color-primary-orange))',
                                }}
                                onClick={() => {
                                    if (open) {
                                        setOpen(false);
                                    }
                                }}
                                className="logo"
                            >
                                <Logo />
                            </Link>
                            <div css={{
                                right: '0',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                zIndex: 2,
                                gap: '18px',
                            }}
                            >

                                <div css={{
                                    marginRight: '-8px',
                                }}
                                >
                                    <button
                                        type="button"
                                        css={{
                                            width: 'auto',
                                            height: 'auto',
                                            display: 'flex',
                                            padding: '20px',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            transition: '0.2s',
                                        }}
                                        style={{
                                            color: open ? colors.shadesWhite : 'var(--header-color-6, var(--color-primary-purple))',
                                        }}
                                        onClick={() => {
                                            if (open) {
                                                setSubmenu(null);
                                                setMenuDirection('forward');
                                            }
                                            setOpen(!open);
                                        }}
                                    >
                                        <VisuallyHidden>
                                            Toggle Menu
                                        </VisuallyHidden>
                                        <div css={{
                                            position: 'absolute',
                                        }}
                                        >
                                            <div
                                                css={{
                                                    width: '19px',
                                                    height: '2px',
                                                    marginBottom: '4px',
                                                    backgroundColor: 'currentColor',
                                                    transformOrigin: '16% center',
                                                    transition: '0.3s',
                                                    borderRadius: borderRadius.full,
                                                }}
                                                style={{
                                                    transform: open ? 'rotate(45deg) translateY(-0px) translateX(2px)' : '',
                                                }}
                                            />
                                            <div
                                                css={{
                                                    width: '19px',
                                                    height: '2px',
                                                    marginBottom: '4px',
                                                    backgroundColor: 'currentColor',
                                                    transformOrigin: '45% center',
                                                    transition: '0.3s',
                                                    borderRadius: borderRadius.full,
                                                }}
                                                style={{
                                                    opacity: open ? '0' : '1',
                                                    transform: open ? 'translateX(100%)' : 'translateX(0%)',
                                                }}
                                            />
                                            <div
                                                css={{
                                                    width: '19px',
                                                    height: '2px',
                                                    backgroundColor: 'currentColor',
                                                    transformOrigin: '16% center',
                                                    transition: '0.3s',
                                                    borderRadius: borderRadius.full,
                                                }}
                                                style={{
                                                    transform: open ? 'rotate(-45deg) translateX(2.5px) translateY(0px)' : '',
                                                }}
                                            />
                                        </div>
                                    </button>
                                </div>
                                {localSupplementalData?.headerGetStartedLink
                                    && localSupplementalData.headerGetStartedLink.map(link => (
                                        <div css={{
                                            right: '0',
                                            height: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            zIndex: 2,
                                            [breakpoints.mb]: {
                                                display: 'none',
                                            },
                                        }}
                                        >
                                            <StyledButtonAsLink variant="header" to={getLink(link?.link)}>
                                                {link?.title}
                                            </StyledButtonAsLink>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </Container>
                    {drawerRef?.current && (
                        <Drawer open={open}>
                            <DrawerContent
                                animation="fade"
                                placement="top"
                                size="full"
                                container={drawerRef?.current || undefined}
                            >
                                <div css={{
                                    backgroundColor: colors.primaryLightOrange,
                                    height: '100%',
                                    position: 'relative',
                                }}
                                >
                                    <Background
                                        breakpoints={{
                                            dt: {
                                                gradient: 'orange',
                                            },
                                        }}
                                        css={{
                                            opacity: 0.3,
                                            height: '100%',
                                            transform: 'scale(3)',
                                            animation: `${backgroundAnimation} 0.5s forwards`,
                                            animationDelay: '0.15s',
                                            position: 'absolute',
                                            width: '100%',
                                            top: '0',
                                            left: '0',
                                        }}
                                    />
                                    <div css={{
                                        height: '100%',
                                        maxWidth: '250px',
                                        opacity: '0',
                                        animation: `${backgroundAnimationLogo} 1.6s forwards`,
                                        animationDelay: '0.3s',
                                        position: 'absolute',
                                        width: '100%',
                                        top: '-25vh',
                                        right: '0',
                                    }}
                                    >
                                        <ShadowLogo
                                            css={{
                                                height: '100%',
                                            }}
                                        />
                                    </div>
                                    <Container css={{
                                        paddingBottom: '48px',
                                        position: 'relative',
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        overflowY: 'scroll',
                                        WebkitOverflowScrolling: 'touch',
                                    }}
                                    >
                                        <div
                                            css={{
                                                flexGrow: '1',
                                                marginTop: '104px',
                                                width: '100%',
                                                height: 'auto',
                                                display: 'block',

                                            }}
                                        >
                                            {menuItemSet && (
                                                <DirectionTransition
                                                    shouldChange={JSON.stringify(menuItemSet)}
                                                    direction={menuDirection}
                                                    css={{
                                                        height: 'calc(100% - 48px)',
                                                        display: 'flex',
                                                        paddingTop: hasAnnouncement ? '44px' : '0',
                                                        flexDirection: 'column',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <div>
                                                        {subMenu && (
                                                            <>
                                                                <Button
                                                                    key="back"
                                                                    onClick={() => {
                                                                        setMenuDirection('backward');
                                                                        if (subMenu?.treeParent) {
                                                                            const menuItemSet = hierarchicalMenuSelectChild(
                                                                                rawMenuData,
                                                                                subMenu.treeParent.id,
                                                                            );
                                                                            if (menuItemSet) {
                                                                                setSubmenu(menuItemSet);
                                                                                return;
                                                                            }
                                                                        }
                                                                        setSubmenu(null);
                                                                    }}
                                                                    css={{
                                                                        width: '100%',
                                                                        display: 'block',
                                                                        textAlign: 'left',
                                                                        ...smallTextStyles,
                                                                        color: colors.shadesWhite,
                                                                    } as Interpolation<Theme>}
                                                                >
                                                                    <span css={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                                                        <MenuCaretLeft />
                                                                        <span css={{ marginLeft: '12px' }}>Back</span>
                                                                    </span>
                                                                </Button>
                                                                <Typography
                                                                    fontSize={2429}
                                                                    color="shadesWhite"
                                                                    css={{
                                                                        borderBottom: '1px solid #fff',
                                                                        paddingTop: '25px',
                                                                        paddingBottom: '30px',
                                                                    }}
                                                                >
                                                                    {subMenu.title}
                                                                </Typography>
                                                            </>
                                                        )}
                                                        {menuItemSet.childMenu
                                                            .sort((a, b) => a.position - b.position)
                                                            .map((menuItem, idx) => {
                                                                const {
                                                                    id,
                                                                    title,
                                                                    link,
                                                                    childMenu,
                                                                } = menuItem;
                                                                return (
                                                                    <div key={id}>
                                                                        {(childMenu.length > 0 && !subMenu) && (
                                                                            <Typography
                                                                                color="shadesWhite"
                                                                                fontWeight="regular"
                                                                                as="button"
                                                                                onClick={() => {
                                                                                    if (!subMenu) {
                                                                                        setSubmenu(menuItem);
                                                                                        setMenuDirection('forward');
                                                                                    }
                                                                                }}
                                                                                css={{
                                                                                    padding: subMenu ? '25px 0' : '30px 0',
                                                                                    width: '100%',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'space-between',
                                                                                    textAlign: 'left',
                                                                                    borderBottom: idx !== menuItemSet.childMenu.length - 1 ? '1px solid #fff3' : '1px solid transparent',
                                                                                }}
                                                                                fontSize={subMenu ? 1627 : 2429}
                                                                            >
                                                                                {title}
                                                                                <MenuCaretRight />
                                                                            </Typography>
                                                                        )}
                                                                        {(childMenu.length > 0 && subMenu) && (
                                                                            <div css={{
                                                                                width: '100%',
                                                                                borderBottom: idx !== menuItemSet.childMenu.length - 1 ? '1px solid #fff' : '1px solid transparent',
                                                                                paddingBottom: '12px',
                                                                            }}
                                                                            >
                                                                                <Typography
                                                                                    color="shadesWhite"
                                                                                    fontWeight="regular"
                                                                                    as={Link}
                                                                                    to={getLink(link) || ''}
                                                                                    onClick={() => {
                                                                                        setSubmenu(null);
                                                                                        setMenuDirection('forward');
                                                                                        setOpen(false);
                                                                                    }}
                                                                                    css={{
                                                                                        padding: subMenu ? '25px 0' : '30px 0',
                                                                                        width: '100%',
                                                                                        display: 'block',
                                                                                        textAlign: 'left',
                                                                                    }}
                                                                                    fontSize={subMenu ? 1627 : 2429}
                                                                                >
                                                                                    {title}
                                                                                </Typography>
                                                                                {childMenu.sort((a, b) => a.position - b.position).map((item, innerIdx) => (
                                                                                    <Typography
                                                                                        as={Link}
                                                                                        color="shadesWhite"
                                                                                        fontWeight="regular"
                                                                                        fontSize={1322}
                                                                                        onClick={() => {
                                                                                            setSubmenu(null);
                                                                                            setOpen(false);
                                                                                            setMenuDirection('forward');
                                                                                        }}
                                                                                        to={getLink(item.link) || ''}
                                                                                        css={{
                                                                                            paddingTop: innerIdx !== 0 ? '15px' : '0',
                                                                                            paddingBottom: '15px',
                                                                                            width: '100%',
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'space-between',
                                                                                            borderBottom: innerIdx !== childMenu.length - 1 ? '1px solid #fff3' : '1px solid transparent',
                                                                                        }}
                                                                                    >
                                                                                        {item.title}
                                                                                        <MenuCaretRight />
                                                                                    </Typography>
                                                                                ))}
                                                                            </div>
                                                                        )}
                                                                        {childMenu.length === 0 && (
                                                                            <Typography
                                                                                as={Link}
                                                                                color="shadesWhite"
                                                                                fontWeight="regular"
                                                                                fontSize={subMenu ? 1627 : 2429}
                                                                                onClick={() => {
                                                                                    setSubmenu(null);
                                                                                    setOpen(false);
                                                                                    setMenuDirection('forward');
                                                                                }}
                                                                                to={getLink(link) || ''}
                                                                                css={{
                                                                                    padding: subMenu ? '25px 0' : '30px 0',
                                                                                    width: '100%',
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'space-between',
                                                                                    borderBottom: idx !== menuItemSet.childMenu.length - 1 ? '1px solid #fff3' : '1px solid transparent',
                                                                                }}
                                                                            >
                                                                                {title}
                                                                                <MenuCaretRight />
                                                                            </Typography>
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                    {(!subMenu) && (
                                                        <div
                                                            key="sups"
                                                            css={{
                                                                color: colors.shadesWhite,
                                                            }}
                                                        >
                                                            <GatsbyLink
                                                                onClick={() => {
                                                                    setSubmenu(null);
                                                                    setOpen(false);
                                                                    setMenuDirection('forward');
                                                                }}
                                                                to={getPath('/sign-in', language)}
                                                                css={{
                                                                    padding: '24px 0',
                                                                    width: '100%',
                                                                    display: 'block',
                                                                    ...smallTextStyles,
                                                                    borderTop: '1px solid #fff3',
                                                                } as Interpolation<Theme>}
                                                            >
                                                                Login To Uptick
                                                            </GatsbyLink>
                                                            <div css={{
                                                                borderTop: '1px solid #fff3',
                                                                padding: '11.5px 0',
                                                            }}
                                                            >
                                                                <LanguageSwitcher />
                                                            </div>
                                                            <div css={{
                                                                padding: '24px 0',
                                                                width: '100%',
                                                                ...smallTextStyles,
                                                                borderTop: '1px solid #fff3',
                                                                display: 'flex',
                                                                position: 'relative',
                                                                justifyContent: 'space-between',
                                                                alignItems: 'center',
                                                                flexFlow: 'wrap',
                                                                gap: '8px',
                                                                a: {
                                                                    whiteSpace: 'nowrap',
                                                                },
                                                            } as Interpolation<Theme>}
                                                            >
                                                                <div>
                                                                    <a href={`mailto:${localSupplementalData.emailAddress as string}`}>
                                                                        {localSupplementalData.emailAddress}
                                                                    </a>
                                                                </div>
                                                                <div
                                                                    aria-hidden="true"
                                                                    css={{
                                                                        display: 'block',
                                                                        content: '""',
                                                                        position: 'relative',
                                                                        height: '17px',
                                                                        width: '1px',
                                                                        backgroundColor: colors.shadesWhite,
                                                                        opacity: 0.3,
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </DirectionTransition>
                                            )}
                                            {localSupplementalData?.headerGetStartedLink
                                                && localSupplementalData.headerGetStartedLink.map(link => (
                                                    <ScrollEffect
                                                        inViewProperties={{
                                                            opacity: [0, 1],
                                                        }}
                                                        inViewDelay={0.3}
                                                        inViewTransitionTime={0.3}
                                                    >
                                                        <StyledButtonAsLink
                                                            css={{
                                                                width: '100%',
                                                            }}
                                                            variant="tertiary"
                                                            to={getLink(link?.link)}
                                                            onClick={() => {
                                                                setSubmenu(null);
                                                                setOpen(false);
                                                                setMenuDirection('forward');
                                                            }}
                                                        >
                                                            {link?.title}
                                                        </StyledButtonAsLink>
                                                    </ScrollEffect>
                                                ))}
                                        </div>
                                    </Container>
                                </div>
                            </DrawerContent>
                        </Drawer>
                    )}
                </div>
            </div>
            {localSupplementalData?.headerGetStartedLink
                && localSupplementalData.headerGetStartedLink.map(link => (
                    <div css={{
                        display: 'none',
                        alignItems: 'center',
                        position: 'fixed',
                        bottom: '0',
                        width: '100%',
                        transform: location.pathname.includes(getLink(link?.link)) ? 'translateY(100%)' : 'translateY(0%)',
                        transition: '0.3s',
                        boxShadow: '0 0 13px rgba(0, 0, 0, 0.08)',
                        [breakpoints.mb]: {
                            display: 'flex'
                        }
                    }}
                    >
                        <StyledButtonAsLink variant="darkPrimary" to={getLink(link?.link)}>
                            {link?.title}
                        </StyledButtonAsLink>
                    </div>
                ))}
            </>
    );
};

export default MobileHeader;
